import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {ArrowBack, ArrowForward} from '@styled-icons/evaicons-solid';
import Carousel from 'nuka-carousel';
import useDimension from '../../../src/hooks/use-dimension';
import Tracking from '../../../src/Tracking';
const appConfig = require('../../../src/data.json');

function Promo({promoZone, slidesToShow, length, idx}) {
  useEffect(() => {
    Tracking.addPromotionImpression(promoZone);
  }, [promoZone]);

  return (
    <PromoWrapper slidesToShow={slidesToShow} length={length} idx={idx}>
      <Image image={`${promoZone.image}`} height={'276px'} />
      <Label>
        <Button
          target={'_self'}
          href={`/products?zone=${promoZone.id}`}
          onClick={() => {
            Tracking.addPromotionImpressionClick(promoZone);
          }}
          className="hover-reverse-btn"
          type="primary">
          {promoZone.label}
        </Button>
      </Label>
    </PromoWrapper>
  );
}

export default function PromoZoneBottomSection(props) {
  const {promoZones} = props;
  const {dimension} = useDimension();

  const renderLeftIcon = ({previousSlide}) => (
    <IconContainer
      onClick={previousSlide}
      aria-label="Prev Banner"
      style={{position: 'absolute', left: -50}}>
      <ArrowBack
        color={appConfig.colors.main}
        style={{width: 20, height: 20}}
      />
    </IconContainer>
  );

  const renderRightIcon = ({nextSlide}) => (
    <IconContainer
      onClick={nextSlide}
      aria-label="Next Banner"
      style={{position: 'absolute', right: -50}}>
      <ArrowForward
        color={appConfig.colors.main}
        style={{width: 20, height: 20}}
      />
    </IconContainer>
  );

  const getSlideWidth = (inWidth) => {
    if (inWidth <= appConfig.breakpoints.lg) {
      return 1;
    }
    return 2;
  };

  const slidesToShow = getSlideWidth(dimension.innerWidth);

  return (
    <Wrapper style={{overflow: 'auto'}}>
      <Carousel
        width={'100%'}
        height={'100%'}
        renderBottomCenterControls={() => false}
        renderCenterLeftControls={renderLeftIcon}
        renderCenterRightControls={renderRightIcon}
        // slideIndex={slideIndex}
        // afterSlide={slideIndex => this.setState({ slideIndex })}
        slidesToShow={slidesToShow}
        slidesToScroll={slidesToShow}>
        {promoZones
          .sort((a, b) => {
            if (a.priority === null) {
              return 1;
            } else if (b.priority === null) {
              return -1;
            }
            return a.priority - b.priority;
          })
          .map((promoZone, idx) => (
            <Promo
              promoZone={promoZone}
              key={idx}
              slidesToShow={slidesToShow}
              length={promoZones.length}
              idx={idx}
            />
          ))}
      </Carousel>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: 0px 50px;
`;

const PromoWrapper = styled.div`
  margin-right: ${(props) =>
    props.slidesToShow === 2
      ? props.idx + 1 === props.length
        ? '0px'
        : (props.idx + 1) % 2 === 0
        ? '0px'
        : '30px'
      : '0px'};
`;

const Image = styled.div`
  cursor: pointer;
  background-image: url(${(props) => props.image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #e0e0e0;
  height: ${(props) => props.height || '200px'};

  &:hover {
    opacity: 0.75;
  }
`;

const Label = styled.div`
  margin-top: 33px;
  border-radius: 3px;

  display: flex;
  justify-content: center;

  & > p {
    color: white;
    margin: 0px;
  }

  & .hover-reverse-btn {
    background-color: ${appConfig.colors.main};
    color: white;
    flex-grow: 0;
    border: 0;
    &:hover {
      color: ${appConfig.colors.main};
      background-color: white;
    }
  }
`;

const IconContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  background-color: ${appConfig.colors.third};
  border-radius: 50%;
  border: 0px;
  width: 40px;
  height: 40px;

  :hover {
    background-color: #0004;
  }

  :focus {
    outline: none;
  }

  position: relative;
`;
