import React, {useContext, useCallback, useState, useEffect} from 'react';
import styled from 'styled-components';
import {Context} from '../../../src/AppContext';
import {Button, Row, Col} from 'antd';
import StaticImage from '../../../src/Components/StaticImage';
import {navigate} from 'gatsby';
import ProductItem from '../../../src/Components/ProductItem';
import BlogItem from '../../../src/Components/BlogItem';
import Carousel from './BannerCarousel';
import PromoZoneTopSection from './PromoZoneTopSection';
import PromoZoneBottomSection from './PromoZoneBottomSection';
import IntroSection from './IntroSection';
import SupportSection from './SupportSection';
import SocialSection from './SocialSection';
const appConfig = require('../../../src/data.json');
const slugify = require('uslug');

const intro_items = [
  {
    icon: '/images/home-icon-01.png',
    title: '客製化印製',
    subtitle: '客製化服務，一張也能印',
  },
  {
    icon: '/images/home-icon-02.png',
    title: '材質防水耐磨',
    subtitle: '頂級規格材質，產品超優質',
  },
  {
    icon: '/images/home-icon-03.png',
    title: '專人客服窗口',
    subtitle: '加LINE就能聯繫，快速有效率',
  },
  {
    icon: '/images/home-icon-04.png',
    title: '專業品質把關',
    subtitle: '專人盯稿看印，高品質製程',
  },
];

export default function LandingPage(props) {
  const app = useContext(Context);
  const {promoItems} = app.state;
  const [products, setProducts] = useState([]);
  const [blogs, setBlogs] = useState([]);

  const getProducts = useCallback(async () => {
    try {
      let resp = await app.actions.getProducts();
      setProducts(
        resp.filter((p) => app.state.productNames.indexOf(p.name) > -1),
      );
    } catch (err) {
      console.warn(err);
    }
  }, [app.actions, app.state.productNames]);

  const getBlogs = useCallback(async () => {
    try {
      let resp = await app.actions.getBlogs({
        query: {
          $and: [
            {
              label: {$not: {$regex: 'FAQ'}},
            },
            {
              label: {$not: {$regex: 'draft'}},
            },
          ],
        },
        sorting: ['-created'],
      });
      setBlogs(resp);
    } catch (ex) {
      console.warn(ex);
    }
  }, [app.actions]);

  useEffect(() => {
    (async () => {
      app.actions.setLoading(true);
      await Promise.all([getProducts(), getBlogs()]);
      app.actions.setLoading(false);
    })();
  }, [app.actions, getProducts, getBlogs]);

  return (
    <Wrapper>
      <div className="banner-container">
        <Carousel imagesArray={promoItems.filter((x) => x.type === 'banner')} />
      </div>

      <div className="center-content">
        <div className="intro-items">
          {intro_items.map((item, idx) => (
            <ServiceItem index={idx} key={idx} length={intro_items.length}>
              <img src={item.icon} height="50" width="50" alt="not found" />
              <div className="col">
                <p className="title">{item.title}</p>
                <p className="subtitle">{item.subtitle}</p>
              </div>
            </ServiceItem>
          ))}
        </div>
      </div>

      <div className="center-content">
        <div className="col-section">
          <p className="title">LATEST NEWS｜最新消息</p>
        </div>
        <div className={'sub-promote-banner-wrapper'}>
          <PromoZoneTopSection
            promoZones={promoItems.filter((x) => x.type === 'top_zone')}
          />
        </div>
      </div>

      <BgColorWrapper style={{marginBottom: 50}}>
        <div className="container">
          <IntroSection />
        </div>
      </BgColorWrapper>

      <div className="center-content">
        <div className="col-section">
          <p className="title">BEST SELLER｜熱門商品</p>
          <Row
            gutter={[appConfig.productGridGutter, appConfig.productGridGutter]}
            style={{alignSelf: 'stretch', marginBottom: 50}}>
            {products
              .filter((p) => p.tag === 'HOT')
              .slice(0, 8)
              .map((p, idx) => {
                return (
                  <Col xs={12} md={8} lg={6} key={idx}>
                    <ProductItem
                      product={p}
                      onClick={() => {
                        navigate(`/product/${p.id}`);
                      }}
                    />
                  </Col>
                );
              })}
          </Row>
        </div>
      </div>

      <div
        style={{
          background: '#f7f7f7',
          paddingTop: 15,
          paddingBottom: 15,
          marginBottom: 50,
        }}>
        <div className="center-content">
          <div className="col-section">
            <p className="title">PROMO ZONE｜活動專區</p>
            <PromoZoneBottomSection
              promoZones={promoItems.filter((x) => x.type === 'bottom_zone')}
            />
          </div>
        </div>
      </div>

      <div className="center-content">
        <div className="col-section">
          <p className="title">SUPPOER｜支持計畫</p>
        </div>
        <SupportSection />
      </div>

      <div className="center-content">
        <div className="col-section">
          <p className="title">NEWS｜最新文章</p>
          <Row gutter={20} style={{alignSelf: 'stretch', marginBottom: 50}}>
            {blogs.slice(0, 3).map((record) => (
              <Col xs={24} md={8} key={record.id}>
                <BlogItem record={record} extraStyle={{}} type="portrait" />
              </Col>
            ))}
          </Row>
        </div>
      </div>

      <BgColorWrapper>
        <div className="container">
          <SocialSection />
        </div>
      </BgColorWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #fff;

  .banner-container {
    width: 100%;
    margin: 0 auto 40px;

    @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
      padding-top: 0px;
    }
  }

  .center-content {
    margin: 0px auto;
    max-width: ${appConfig.maxContentWidth}px;
    @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
      padding: 0px 15px;
    }

    & > .sub-promote-banner-wrapper {
      overflow: hidden;
      margin-bottom: 60px;
      @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
        padding-top: 15px;
      }
    }

    & .col-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 17px 0;

      & > .title {
        text-align: center;
        font-weight: 500;
        font-size: 26px;
        letter-spacing: 1px;
        color: ${appConfig.colors.main};
        margin: 10px 0 30px 0;
      }

      @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
        & > .title {
          font-size: 20px;
          margin: 10px 0px 20px;
        }
      }
    }
  }

  .intro-items {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;

    @media screen and (max-width: ${appConfig.breakpoints.sm}px) {
      flex-direction: column;
    }
  }

  & .col {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    & > .title {
      letter-spacing: 2px;
      color: #5a5a5a;
      margin-bottom: 5px;
    }

    & > .subtitle {
      color: #5a5a5a;
      font-size: 10px;
    }
  }
`;

const ServiceItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-basis: 25%;
  padding: 10px 0px;
  border-left: ${(props) => (props.index !== 0 ? '1px solid #bdbdbd' : 'none')};
  align-items: center;

  @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
    flex-basis: 50%;
    border-left: ${(props) =>
      props.index % 2 === 0 ? 'none' : '1px solid #bdbdbd'};
    border-bottom: ${(props) =>
      props.index < 2 ? '1px solid #bdbdbd' : 'none'};
  }

  @media screen and (max-width: ${appConfig.breakpoints.sm}px) {
    flex-basis: 100%;
    justify-content: flex-start;
    border: 0px;
    border-bottom: ${(props) =>
      props.index === props.length - 1 ? '0px' : '1px solid #bdbdbd'};
  }

  & .col {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    & > .subtitle {
      color: ${appConfig.colors.text};
      font-size: ${appConfig.fontSize.md}px;
    }

    & p {
      margin-bottom: 0px;
    }

    & > .title {
      letter-spacing: 2px;
      color: ${appConfig.colors.main};
      margin-bottom: 5px;
      font-size: ${appConfig.fontSize.xl}px;
    }
  }
  ${(props) => props.css}
`;

const BgColorWrapper = styled.div`
  background-color: ${appConfig.colors.third};

  & > .container {
    max-width: ${appConfig.maxContentWidth + 40}px;
    margin: 0 auto;
    padding: 37px 0px;

    display: flex;
    justify-content: space-between;

    & > .item {
      flex: 1;
      margin: 20px;
    }
  }

  @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
    & > .container {
      flex-direction: column;
    }
  }
`;
