import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {navigate} from 'gatsby';
const appConfig = require('../../../src/data.json');

const BRANDS = [
  {icon: '/images/home-icon-05.png', text: '大圖輸出'},
  {icon: '/images/home-icon-06.png', text: 'UV印刷'},
  {icon: '/images/home-icon-07.png', text: '急件印刷'},
  {icon: '/images/home-icon-08.png', text: '少量輸出'},
  {icon: '/images/home-icon-09.png', text: '名片DM'},
  {icon: '/images/home-icon-10.png', text: '印刷機銷售'},
];

function IntroSection(props) {
  return (
    <Wrapper>
      <div className="container">
        <div className="content">
          <h3 className="title">關於建鏵 ABOUT</h3>
          <div className="text">{`不印不知道，來印就回不去了！\n需要高品質的少量印刷嗎？建鏵為您提供服務！\n建鏵印刷為您提供多種不同的選擇，UV印刷的貼紙顏色鮮豔，有特殊的磨砂質感，甚至還可以重覆黏貼不殘膠，不須開刀模就可以讓你的貼紙有切割線，不像外面一次印就需要大量下單～建鏵給你最好的選擇！`}</div>
          <Button
            className="hover-reverse-btn"
            onClick={() => navigate('/about')}>
            關於建鏵
          </Button>
        </div>
      </div>
      <div className="container">
        <div className="items">
          {BRANDS.map((item, index) => (
            <IconItem>
              <img alt="icon" src={item.icon} />
              <div>{item.text}</div>
            </IconItem>
          ))}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;

  & > .container {
    flex: 1;

    & > .content {
      max-width: 620px;
      width: 100%;
      padding: 20px;

      & > .title {
        margin-bottom: 28px;
        font-size: 26px;
        color: ${appConfig.colors.main};
      }

      & > .text {
        margin-bottom: 36px;
        white-space: pre-wrap;
        line-height: 1.88;
        letter-spacing: 1.58px;
        color: ${appConfig.colors.text};
      }

      & > .hover-reverse-btn {
        max-width: 210px;
        width: 100%;
        border-radius: 5px;
        background-color: ${appConfig.colors.main};
        color: white;

        &:hover {
          color: ${appConfig.colors.main};
          background-color: #ffffff;
        }
      }
    }

    & > .items {
      margin: 0 auto;
      max-width: 500px;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
    flex-direction: column;

    & > .container {
      & > .content {
        margin: 0 auto;
        text-align: center;

        & > .title {
          margin-bottom: 20px;
          font-size: 20px;
        }

        & > .text {
          font-size: 14px;
        }
      }
    }
  }
`;

const IconItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 28px;

  & > img {
    width: 86px;
    height: 86px;
  }

  & > div {
    margin-top: 5px;
    width: 86px;
    font-weight: 500;
    text-align: center;
    color: ${appConfig.colors.main};
  }
`;

export default IntroSection;
