import React from 'react';
import styled from 'styled-components';
import StaticImage from '../../../src/Components/StaticImage';
import {Link} from 'gatsby';
const appConfig = require('../../../src/data.json');

function SupportSection(props) {
  return (
    <Wrapper>
      <Item to={`/articles/${'原創設計'}`}>
        <StaticImage
          filename="home-support-01.png"
          style={{width: '100%', height: '100%'}}
        />
        <div className="title">支持設計原創商品</div>
        <div className="desp">
          <p>{`為了帶給用戶更多元的設計印刷商品選擇，\n及推廣優秀圖文設計師，建鏵協力共同開發，\n更多樣性的原創設計品項。`}</p>
        </div>
      </Item>
      <div className="divider" />
      <Item to={`/articles/${'支持公益'}`}>
        <StaticImage
          filename="home-support-02.png"
          style={{width: '100%', height: '100%'}}
        />
        <div className="title">支持公益愛心義賣</div>
        <div className="desp">
          <p>{`國內身心障礙者社福資源與專業支持仍然不足，\n建鏵提供支持公益愛心贊助平台，\n希望大家一同成為支持的力量。`}</p>
        </div>
      </Item>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 60px;

  & > .divider {
    width: 30px;
  }

  @media screen and (max-width: ${appConfig.breakpoints.sm}px) {
    flex-direction: column;

    & > .divider {
      height: 30px;
    }
  }
`;

const Item = styled(Link)`
  max-width: 605px;
  width: 100%;

  & > .title {
    width: 100%;
    margin-bottom: 25px;
    padding: 12px;
    background-color: ${appConfig.colors.main};

    line-height: 1.46;
    letter-spacing: 0.6px;
    color: #ffffff;
    text-align: center;
    font-size: ${appConfig.fontSize.md}px;
  }

  & > .desp {
    width: 100%;

    & > p {
      margin: 0 auto;
      max-width: 296px;
      width: 100%;
      line-height: 1.56;
      color: ${appConfig.colors.text};
      text-align: center;
      font-size: ${appConfig.fontSize.sm}px;
      white-space: pre;
    }
  }

  ${(props) => props.css || ''}
`;

export default SupportSection;
