import React from 'react';
import styled from 'styled-components';
const appConfig = require('../../../src/data.json');

const SOCIAL_LIST_FORWARD = [
  {
    link: '/articles',
    src: '/images/home-case.jpg',
  },
  {
    link: '/faq',
    src: '/images/home-faq.jpg',
  },
];

const SOCIAL_LIST_BACKWARD = [
  {
    link: appConfig.socialMedia.facebook.link,
    src: '/images/home-fb.jpg',
  },
  {
    link: appConfig.socialMedia.line.link,
    src: '/images/home-line.jpg',
  },
];

function SocialButton(props) {
  const {item} = props;
  return (
    <ButtonWrapper
      src={item.src}
      target="_blank"
      href={item.link}
      rel="noreferrer">
      {/* <div>{item.text}</div> */}
      <div className="bg-img" />
    </ButtonWrapper>
  );
}

const ButtonWrapper = styled.a`
  margin: 10px 20px;
  max-width: 297px;
  width: 100%;

  display: flex;
  justify-content: center;

  & > div {
    width: 100%;
    min-height: 80px;

    background-image: url(${(props) => props.src});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
`;

function SocialSection(props) {
  return (
    <Wrapper>
      <div className="container">
        {SOCIAL_LIST_FORWARD.map((item) => (
          <SocialButton item={item} />
        ))}
      </div>

      <div className="container">
        {SOCIAL_LIST_BACKWARD.map((item) => (
          <SocialButton item={item} />
        ))}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;

  & > .container {
    display: flex;
    justify-content: space-around;
    flex: 1;
  }

  @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
    flex-direction: column;
  }

  @media screen and (max-width: ${appConfig.breakpoints.sm}px) {
    & > .container {
      flex-direction: column;
      align-items: center;
    }
  }
`;

export default SocialSection;
