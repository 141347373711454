import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Row, Col} from 'antd';
import Tracking from '../../../src/Tracking';

const RATIO = 300 / 300;

function Promo({promoZone}) {
  useEffect(() => {
    Tracking.addPromotionImpression(promoZone);
  }, [promoZone]);

  return (
    <a
      target={'_blank'}
      rel="noreferrer"
      href={promoZone.link}
      onClick={() => {
        Tracking.addPromotionImpressionClick(promoZone);
      }}>
      <ImageWrapper>
        <img
          src={`${promoZone.image}`}
          alt="top promote"
          style={{width: '100%', height: '100%'}}
          width="300"
          height="300"
        />
      </ImageWrapper>
    </a>
  );
}

export default function PromoZoneTopSection(props) {
  const {promoZones} = props;

  return (
    <Wrapper>
      <Row gutter={20}>
        {promoZones
          .sort((a, b) => {
            if (a.priority === null) {
              return 1;
            } else if (b.priority === null) {
              return -1;
            }
            return a.priority - b.priority;
          })
          .slice(0, 3)
          .map((promoZone, idx) => (
            <Col xs={24} md={8} style={{marginBottom: 15}} key={idx}>
              <Promo promoZone={promoZone} />
            </Col>
          ))}
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: ${RATIO * 100}%;

  > img {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
`;
